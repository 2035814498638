/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $('ul.menu-nav li').each(function(){
    if($(this).hasClass('menu-item-has-children')){
      $(this).addClass('dropdown');
      $(this).children('a').append('<span class="caret"></span>');
      $(this).find('ul').addClass('dropdown-menu');
    }
  });

  if(window.matchMedia("(max-width: 768px)").matches){
    $('li.menu-item-has-children').on('click', function(event){
      event.preventDefault();
      $('li.menu-item-has-children').removeClass('active');
      $(this).addClass('active');
      $('li.menu-item-has-children:not(".active")').children('ul').hide();
      $(this).children('ul').slideToggle();
    });
    $('li.menu-item-has-children > ul > li a').on('click', function(event){
      event.stopPropagation();
    });
  }

  // IE + ancre sans href
  $('a:not([href])').css('cursor','default');

  //SLIDER
  $(window).on('load', function(){
    $('.flexslider').flexslider({
      animation: "fade",
      animationSpeed: 900,
      slideshowSpeed: 4000,
      startAt: 0
    });
  });

  //MENU - SEARCH C
  $(".btn-search").click(function () {
    $(this).closest(".search-box-menu").find(".search-box").toggle();
  });

  //MENU - MOBILE
  $(".navbar-toggle").click(function () {
      $(this).closest('.navbar').find('.navbar-collapse').slideToggle();
  });
//  $("body").on("click", "[data-toggle='dropdown']", function () {
//      var href = $(this).attr("href");
//      if (!isEmpty(href) && href.length > 5 && !href.startsWith("#")) document.location = href;
//  });
  $('.menu-item-has-children a').click(function(){
      document.location = $(this).attr("href");
      $(this).closest('li').find('ul').css({'height':'0','overflow':'hidden'});
  });

  //PROCESS TEXT
  $('.process-read-more').click(function(){
    $(this).toggleClass('active');
    $(this).closest('.process-item').find('.process-text').toggleClass('active');
  });

  //PROCESS
  // var set = $('.process-item');
  // var length = set.length;
  // set.each(function(index, element) {
  //       thisVal = $(this).text();
  //       if(parseInt(thisVal) !== 0) {
  //           if (index === (length - 1)) {
  //             $(this).addClass('last-process-item');
  //             console.log(element);
  //           }
  //       }
  // });

  //TEXTBOX
  $('.textbox-read-more').click(function(){
    $(this).toggleClass('active');
    $(this).closest('.textbox-small').toggleClass('active');
  });

  //LIST
  $('.accordion-list span').click(function(){
    $(this).toggleClass('active');
    $(this).closest('.list-group-item').toggleClass('active-panel');
    $(this).closest('.list-group-item').find('.panel').slideToggle();
  });

  //IMGWTEXT
  $('.imgWtext-read-more').click(function(){
    $(this).toggleClass('active');
    $(this).closest('.imgWtext-small').find('.imgWtext-text').toggleClass('active');
  });

  //LONGTEXT
  $('.longtext-read-more').click(function(){
    $(this).toggleClass('active');
    $(this).closest('.longtext').find('.longtext-text').toggleClass('active');
  });

  //FORM
  $('.preciser-autre').hide();

  $('.forme-juridique').change(function(){
    var value = $(this).find('option:selected').text();

    if (value == 'Autre') {
      $('.preciser-autre').show();
      $('.preciser-autre input').attr('placeholder', 'Forme juridique à préciser');
      $('.preciser-autre input').removeAttr('value', '');
    } else {
      $('.preciser-autre').hide();
      $('.preciser-autre input').removeAttr('placeholder');
      $('.preciser-autre input').attr('value', 'Pas de précision requise');
    }
  });

  //LOADER
  $('#preloader').fadeOut(400);

  //SCROLLTOP
  $(window).scroll(function(){
    if ($(window).scrollTop() >= 200){
      $('.scroll-top').show();
    } else{
      $('.scroll-top').hide();
    }
  });

  $('.scroll-top').click(function(){
    $('html, body').animate({
        scrollTop: 0
    }, 1000);
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('iframe').removeAttr('frameborder webkitallowfullscreen mozallowfullscreen');

})(jQuery); // Fully reference jQuery after this point.
